import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import service from '@/services/service';
import formbricks from '@formbricks/js/app';

import { encryptStorage } from '@/config/encryptStorage';

import Login from '@/views/Login';
import NewAccreditation from '@/views/NewAccreditation';
import AccountInformation from '@/views/AccountInformation';
import AccountSecurity from '@/views/AccountSecurity';
import AccountCustomization from '@/views/AccountCustomization';
import AccountSalePlan from '@/views/SalePlan';
import Account from '@/views/Account';
import BankAccount from '@/views/BankAccount';
import Dashboard from '@/views/Dashboard';
import Forgot from '@/views/Forgot';
import NotFound from '@/views/NotFound';
import Reset from '@/views/Reset';
import Extract from '@/views/Extract';
import Receivable from '@/views/Receivable';
import NewSale from '@/views/NewSale/New';
import NewSaleResume from '@/views/NewSale/Resume';
import NewSaleSuccess from '@/views/NewSale/Success';
import Customer from '@/views/Customer';
import Supplier from '@/views/Supplier';
import Product from '@/views/Product';
import Service from '@/views/Service';
import Transfer from '@/views/Transfer';
import Expense from '@/views/Expense';
import SaleReceivable from '@/views/SaleReceivable';
import Appointment from '@/views/Appointment';
import CashFlow from '@/views/CashFlow';
import Faq from '@/views/Faq';
import Term from '@/views/Term';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      title: 'Entrar',
      requiresAuth: false,
    }
  },

  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot,
    meta: {
      title: 'Redefinir senha',
      requiresAuth: false,
    }
  },

  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    meta: {
      title: 'Redefinir senha',
      requiresAuth: false,
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Painel',
      requiresAuth: true,
      isEnabled: true,
      showStatusInfoModal: true,
    }
  },

  {
    path: '/bank',
    name: 'bank-account',
    component: BankAccount,
    meta: {
      title: 'Conta bancária',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/accreditation',
    name: 'accreditation',
    component: NewAccreditation,
    meta: {
      title: 'Credenciamento',
      isAccreditation: true,
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      title: 'Conta',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/account/informations/:section',
    name: 'account-informations',
    component: AccountInformation,
    meta: {
      title: 'Informações da conta',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/account/sales-plan',
    name: 'sales-plan',
    component: AccountSalePlan,
    meta: {
      title: 'Plano e taxas de venda',
      requiresAuth: true,
      isEnabled: true,
      showStatusInfoModal: true,
    }
  },

  {
    path: '/account/customization',
    name: 'account-customization',
    component: AccountCustomization,
    meta: {
      title: 'Customização',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/account/security',
    name: 'account-security',
    component: AccountSecurity,
    meta: {
      title: 'Segurança e login',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/account/faq',
    name: 'account-faq',
    component: Faq,
    meta: {
      title: 'Perguntas frequentes',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/extract',
    name: 'extract',
    component: Extract,
    meta: {
      title: 'Extrato e saldo',
      requiresAuth: true,
      isEnabled: true,
      showStatusInfoModal: true,
    }
  },

  {
    path: '/receivables',
    name: 'receivables',
    component: Receivable,
    meta: {
      title: 'Recebíveis',
      requiresAuth: true,
      isEnabled: true,
      showStatusInfoModal: true,
    }
  },

  {
    path: '/sales-receivables',
    name: 'sale-receivable',
    component: SaleReceivable,
    meta: {
      title: 'Vendas a receber',
      requiresAuth: true,
      isEnabled: true,
      showStatusInfoModal: true,
    }
  },

  {
    path: '/expenses',
    name: 'expense',
    component: Expense,
    meta: {
      title: 'Despesas',
      requiresAuth: true,
      isEnabled: true,
      showStatusInfoModal: true,
    }
  },

  {
    path: '/cash-flow',
    name: 'cash-flow',
    component: CashFlow,
    meta: {
      title: 'Fluxo de caixa',
      requiresAuth: true,
      isEnabled: true,
      showStatusInfoModal: true,
    }
  },

  {
    path: '/sales/new',
    name: 'new-sale',
    component: NewSale,
    meta: {
      title: 'Nova venda',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/sales/resume',
    name: 'new-sale-resume',
    component: NewSaleResume,
    meta: {
      title: 'Resumo da venda',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/sales/success',
    name: 'new-sale-success',
    component: NewSaleSuccess,
    meta: {
      title: 'Sucesso venda',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/transfers',
    name: 'transfers',
    component: Transfer,
    meta: {
      title: 'Saques',
      requiresAuth: true,
      isEnabled: true,
      showStatusInfoModal: true,
    }
  },

  {
    path: '/customers',
    name: 'customers',
    component: Customer,
    meta: {
      title: 'Clientes',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/suppliers',
    name: 'suppliers',
    component: Supplier,
    meta: {
      title: 'Fornecedores',
      requiresAuth: true,
      isEnabled: !service.isDefaultPlatform(),
    }
  },

  {
    path: '/products',
    name: 'products',
    component: Product,
    meta: {
      title: 'Produtos',
      requiresAuth: true,
      isEnabled: !service.isDefaultPlatform(),
    }
  },

  {
    path: '/services',
    name: 'services',
    component: Service,
    meta: {
      title: 'Serviços',
      requiresAuth: true,
      isEnabled: !service.isDefaultPlatform(),
    }
  },

  {
    path: '/appointments',
    name: 'appointments',
    component: Appointment,
    meta: {
      title: 'Agenda',
      requiresAuth: true,
      isEnabled: true,
    }
  },

  {
    path: '/terms',
    name: 'terms',
    component: Term,
    meta: {
      title: 'Termos de uso',
    }
  },

  {
    path: '*',
    name: 'not-found',
    component: NotFound,
    meta: {
      title: 'Erro 404',
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _, next) => {
  const hasSeller = store.getters.getSellers.length > 0;
  const accreditationPath = '/accreditation';
  const dashboardPath = '/dashboard';
  const homePath = '/';
  const hasToken = !!encryptStorage.getItem('token');
  const isSaleEnabled = store.getters.getCurrentSellerSaleStatus;
  const isSellerPeding = store.getters.getIsSellerPending;

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | ${service.getPlatformTitle()}`;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (hasToken) {
      if (
        (!hasSeller && !to.meta.isAccreditation && to.path !== accreditationPath) ||
        (hasSeller && to.path === accreditationPath) ||
        ((!isSaleEnabled || isSellerPeding) && (to.path === '/sales/new' || to.path === '/account/customization')) ||
        (!to.meta.isEnabled || service.isUndefined(to.meta.isEnabled))
      ) {
        next(dashboardPath);
      } else {
        next();
      }
    } else if (to.path !== homePath) {
      store.commit('authModule/setRedirectPath', to.path);
      next(homePath);
    } else {
      next();
    }
  } else {
    if (hasToken && to.path !== dashboardPath) {
      next(dashboardPath);
    } else {
      next();
    }
  }
});

router.afterEach((to) => {
  if (!to.meta?.requiresAuth) {
    const formbricksModal = document.querySelectorAll('#formbricks-modal-container');
    if (formbricksModal.length > 0) {
      formbricksModal.forEach((modal) => {
        modal.remove();
      });
    }
  }

  if (window.formbricks && to.meta?.requiresAuth) {
    formbricks.registerRouteChange();
  }
});

export default router;