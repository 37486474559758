<template>
  <div class="sale-receivable-view-container">
    <default-view-component :hasModal="isShowModal">
      <template v-slot:alert>
        <snackbar-component ref="snackbar" />
      </template>

      <template v-slot:modal>
        <sale-receivable-modal-component
          v-if="isShowModal"
          :saleId="currentSaleId"
          @close="isShowModal = false"
          @finishRequest="fetchData"
          @snackbarNotification="showSnackBar"
        />
      </template>

      <template v-slot:content>
        <div class="section">
          <header-component
            :data="header"
          />

          <div class="header-options">
            <div class="filter-container">
              <select-fetch-field
                class="select-fetch-field"
                name="customerSelect"
                placeholder="Selecione o cliente"
                :options="customers"
                :hasSearch="true"
                :hasFetch="true"
                :hasRequest="hasCustomerRequest"
                @fetch="getCustomerByQuery"
                v-model="customerId"
              />

              <button-component
                class="button-component"
                text="Limpar"
                @click.native="resetFilter"
              />
            </div>

            <button-component
              class="button-component"
              text="Nova venda"
              :isDisabled="hasRequest || getIsSellerPending || !getCurrentSellerSaleStatus"
              v-ripple="$theme.whiteColorTransparent"
              pathView="/sales/new"
            />
          </div>

          <table-header-component
            v-if="tableData.rows.length != 0"
            class="table-header-component"
            :hasRequest="hasRequest && !paginationData.totalCountItems"
            :data="[{
              hasLastChild: false,
              label: `${paginationData.totalCountItems}
                      ${$service.formatStringPlural(paginationData.totalCountItems, 'venda')}`
            }]"
          />

          <table-component
            class="table-component"
            :hasRequest="hasRequest && !isMoreData"
            :table="tableData"
            @actionClick="showModal"
          />

          <pagination-table-component
            v-if="hasTableRows()"
            class="pagination-table-button-component"
            :isDisabled="!haveMoreData()"
            :data="paginationData"
            :hasRequest="hasRequest"
            @onClick="moreData"
          />
        </div>
      </template>
    </default-view-component>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DefaultView from '@/components/Layout/DefaultView';
  import Header from '@/components/Layout/Header';
  import Button from '@/components/Base/Button';
  import TableHeader from '@/components/Table/TableHeader';
  import Table from '@/components/Table/Table';
  import PaginationTableButton from '@/components/Table/PaginationTableButton';
  import SnackBar from '@/components/Base/SnackBar';
  import SaleReceivableModal from '@/components/Modal/SaleReceivableModal';
  import SelectFetchField from '@/components/SelectFetchField';

  export default {
    name: 'SaleReceivableView',

    data() {
      return {
        header: {
          title: 'Vendas',
          to: '/dashboard',
          button: {
            icon: 'icon-arrow-left',
            text: 'Voltar'
          }
        },

        queryParams: [
          {
            query: 'limit',
            param: '500'
          }
        ],

        hasRequest: false,
        isShowModal: false,

        customerId: null,
        customers: [],
        hasCustomerRequest: false,

        currentSaleId: '',

        isMoreData: false,
        allRows: [],

        tableData: this.initialTableData(),
        paginationData: this.initialPaginationData()
      };
    },

    components: {
      'default-view-component': DefaultView,
      'header-component': Header,
      'button-component': Button,
      'table-component': Table,
      'table-header-component': TableHeader,
      'pagination-table-component': PaginationTableButton,
      'snackbar-component': SnackBar,
      'sale-receivable-modal-component': SaleReceivableModal,
      'select-fetch-field': SelectFetchField
    },

    computed: {
      ...mapGetters([
        'getCurrentSellerId',
        'getCurrentSellerData',
        'getCurrentSellerSaleStatus',
        'getCurrentSellerStatus',
        'getIsSellerPending'
      ])
    },

    created() {
      this.fetchData();
    },

    watch: {
      getCurrentSellerId() {
        this.hasRequest = true;
        this.paginationData = this.initialPaginationData();
        this.tableData = this.initialTableData();
      },

      'getCurrentSellerData.id'(value) {
        if (value) {
          this.fetchData();
        }
      },

      customerId() {
        this.hasRequest = true;
        this.paginationData = this.initialPaginationData();
        this.tableData = this.initialTableData();
        this.fetchData();
      }
    },

    mounted() {
      this.getAllCustomers();
    },

    methods: {
      async fetchData() {
        this.hasRequest = true;

        if (!this.isMoreData) {
          this.allRows = [];
          this.tableData.rows = [];
        }

        let queryParams = [
          {
            query: 'limit',
            param: '10'
          },

          {
            query: 'offset',
            param: this.allRows.length
          },

          {
            query: 'customer_id',
            param: this.customerId
          }
        ];

        await this.$store.dispatch('saleModule/getAllSales', this.$service.formatQueryParam(queryParams))
          .then(res => {
            const items = res.items;

            this.paginationData.totalCountItems = res.total_count;

            this.allRows = this.allRows.concat(items);
            this.tableData.rows = this.allRows.map(item => {
              const row = {
                date: this.$service.dateFormat(item.created_at, 'YYYY-MM-DDThh:mm:ss-TZ', 'DD/MM/YY HH:mm'),
                customer: item.customer.name,
                type: item.resource_id ? 'Online' : 'Presencial',
                paymentType: this.parsePaymentType(item.payment_type),
                status: this.parseStatusChipTableData(item.status),
                dueDate: item.due_date ? this.$service.dateFormat(item.due_date, 'YYYY-MM-DDThh:mm:ss-TZ', 'DD/MM/YY HH:mm') : '-',
                amount: this.$service.formatBrlAmountSymbol(item.amount),
                paidAmount: item.paid_amount ? this.$service.formatBrlAmountSymbol(item.paid_amount) : '-',
                actions: {
                  showAction: false,
                  icon: 'icon-more',
                  isDisabled: this.$service.toUpperCase(item.status) != 'PENDING',
                  items: [
                    {
                      id: item.id,
                      type: 'update',
                      icon: 'icon-pencil',
                      label: 'Baixar',
                    }
                  ]
                }
              };

              if (item.resource_url) {
                row.actions.items = [
                  {
                    url: item.resource_url,
                    type: 'copy',
                    icon: 'icon-link',
                    label: 'Copiar link',
                  }
                ];
              }

              return row;
            });

            this.paginationData.countItems = this.allRows.length;
          })
          .catch(() => {
            this.showSnackBar('Erro ao carregar informações', '#FF5A5A');
          });

        this.isMoreData = false;
        this.hasRequest = false;
      },

      moreData() {
        if(!this.haveMoreData()) {
          this.isMoreData = true;

          this.fetchData();
        }
      },

      haveMoreData() {
        return this.paginationData.countItems == this.paginationData.totalCountItems;
      },

      showModal(item) {
        if (item.id) {
          this.currentSaleId = item.id,
          this.isShowModal = true;
        }

        if (item.url) {
          this.$copyText(item.url);
        }
      },

      showSnackBar(text, color) {
        this.$refs?.snackbar?.show(text, color);
      },

      parsePaymentType(type) {
        let label = '';

        switch (this.$service.toUpperCase(type)) {
          case 'BANK_SLIP':
            label = 'Boleto';
            break;
          case 'MONEY':
            label = 'Dinheiro';
            break;
          case 'CREDIT':
            label = 'Crédito';
            break;
          case 'DEBIT':
            label = 'Débito';
            break;
          case 'PIX':
            label = 'Pix';
            break;
          case 'CHECKOUT':
            label = 'Link pagamento';
            break;
          default:
            label = 'Outro';
        }

        return label;
      },

      parseStatusChipTableData(status) {
        var chipData = {
          background: '',
          textColor: '',
          label: ''
        };

        switch (this.$service.toUpperCase(status)) {
          case 'PENDING':
            chipData.background = '#BA8C031C';
            chipData.textColor = '#BA8C03';
            chipData.label = 'Pendente';
            break;
          case 'CANCELED':
            chipData.background = '#FF5A5A1C';
            chipData.textColor = '#FF5A5A';
            chipData.label = 'Cancelado';
            break;
          case 'SUCCEEDED':
            chipData.background = '#08B9781C';
            chipData.textColor = '#08B978';
            chipData.label = 'Aprovado';
            break;
          default:
            chipData.background = '#3333331C';
            chipData.textColor = '#333333';
            chipData.label = 'Outro';
        }

        return chipData;
      },

      hasTableRows() {
        return this.tableData.rows.length != 0;
      },

      initialPaginationData() {
        return {
          countItems: 0,
          totalCountItems: 0
        };
      },

      resetFilter() {
        this.customerId = null;
      },

      initialTableData() {
        return {
          hasIcon: false,
          headers: {
            hasBorder: true,
            items: [
              {
                label: 'Data',
                field: 'date',
                align: 'center'
              },

              {
                label: 'Cliente',
                field: 'customer',
                align: 'center'
              },

              {
                label: 'Tipo',
                field: 'type',
                align: 'center'
              },

              {
                label: 'Forma',
                field: 'paymentType',
                align: 'center'
              },

              {
                label: 'Valor da venda',
                field: 'amount',
                align: 'center'
              },

              {
                label: 'Valor recebido',
                field: 'paidAmount',
                align: 'center'
              },

              {
                label: 'Vencimento',
                field: 'dueDate',
                align: 'center'
              },

              {
                label: 'Status',
                field: 'status',
                align: 'center',
                isChip: true
              },

              {
                label: 'Ações',
                align: 'center',
                field: 'actions',
                isAction: true
              }
            ]
          },

          rows: []
        };
      },

      async getCustomerByQuery(customerSearch) {
        this.hasCustomerRequest = true;

        await this.$store
          .dispatch('customerModule/getAllCustomersFind', customerSearch)
          .then((res) => {
            this.customers = this.formatSelectArr(res.items);
          })
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao pesquisar cliente', '#FF5A5A');
          });

        this.hasCustomerRequest = false;
      },

      async getAllCustomers() {
        this.hasCustomerRequest = true;

        await this.$store
          .dispatch(
            'customerModule/getAllCustomers',
            this.$service.formatQueryParam(this.queryParams)
          )
          .then((res) => {
            this.customers = this.formatSelectArr(res.items);
          })
          .catch(() => {
            this.$refs?.snackbar?.show('Erro ao buscar clientes', '#FF5A5A');
          });

        this.hasCustomerRequest = false;
      },

      formatSelectArr(data) {
        return data.map((item) => ({
          isActive: false,
          label: item.name,
          value: item.id
        }));
      },
    }
  };
</script>

<style lang="scss" scoped>
  .sale-receivable-view-container {
    .section {
      padding: 1.5rem;

      .header-options {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        .filter-container {
          display: flex;
          align-items: center;
          gap: 1rem;
        }

        .select-fetch-field {
          width: 400px;
          height: 48px;
        }

        .button-component {
          height: 48px;
          max-width: 160px;
        }
      }

      .table-component {
        margin-top: 1.5rem;
        overflow-x: auto;
        overflow-y: hidden;
      }

      .pagination-table-button-component {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      }
    }
  }

  @media (max-width: 605px) {
    .sale-receivable-view-container {
      .section {
        .header-options {
          flex-direction: column-reverse;
          margin-bottom: 1rem;

          .button-component {
            height: 32px;
          }
        }
      }
    }
  }
</style>